/*Cellphones from iphone 5*/

@media(min-width: 320px)and (max-width: 540px){
    /* .MuiContainer-root{
        padding-left: 0px!important;
        padding-right: 0px!important;
    } */

    .sepper_header{
        margin: 0 !important;
        padding-left:3%;
    }

    .sepper_body{
        padding: 5% 5% 5% 5%;
    }


    #go_gridContainer_dashboardCustomer{
         
        
    }

    #go_gridContainerXs_dashboardCustomer{
        max-width: 100%;
        flex-basis:94%;
        width: 100%!important;
    }

    

    #go_gridContainer_orderType{
        width:100%;
    }

    #go_selectorAdress{
        justify-content: start;
        padding-left: 25%;
    }

    #go_gridContainer_stepper_form{
        width:93%!important;
    }

    #go_gridContainer_stepper_buttons{
        justify-content: space-around!important;
    }

    #go_gridContainer_stepper_buttons button{
        margin:0.3rem!important;
    }

    #go_gridContainer_buttons{
        width: 100%!important;
        justify-content: space-around!important;
    }

    #go_gridContainer_buttons button{
        margin: 5%!important;
    }

    #go_gridContainer_step5{
        width: 93%!important;
        padding:1%!important;
    }

    .table__header{
        margin-top:0%; 
    }

    #go_tablePackages_th{
        font-size: 0.7rem;
    }

    #go_step4_form{
        width: 94%;
    }

    .text_area-style{
        min-width: 96%!important;
    }

    #go_sumary_address_movil{
        display: block;
        text-align: right;
        margin: 0%;
        line-height:1;
    }
    #go_sumary_address{
        display:none
    }

    #go_gridContainer_stepper_sumary_buttons{
        justify-content: space-around;
    }

    #go_gridContainer_stepper_sumary_buttons button{
        margin:1%;
    }

    #go_gridContainer_orderHostoryDetails{
        padding-right: 7%;
        padding-left: 1%;
    }



    #go_orderDetails_gridContainerButtons button{
        width: 10rem!important;
    }



    #go_boxContainer_customerDetails{
        padding:0%;
        padding-left: 1%;
        padding-right: 7%;
    }

    #go_history_buttonExport{
        width: 90px;
    }

    #go_historyTable_heders{
        display: none;
    }

    #go_historyTable_itemButtons{
        padding-top: 3%;
    }
}