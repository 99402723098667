/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .Box_Login {
        min-width: 70vw;
        max-width: 70vw;
    }
    .Box_custom {
        min-width: 80vw;
        max-width: 80vw;
    }
    .store-md{
        width: 50%;
    }
    .navBar_movil{
        flex-wrap: inherit;
    }    
    .bar_logo{
        width: 50%;
    }
    .sepper_header{
        margin: 0 !important;
    }
    .sepper_body{
        margin: 0 !important;
        overflow-x: scroll;
    }
    .w-20{
        max-width: 40%;
        min-width: 40%;
    }
    .mr-2-r{
        margin-right: 0px !important;
    }
    .newOrder_alingInitial{
        justify-content: start !important;
    }
    .newOrder_tableConteiner{
        overflow-x: scroll;
        width: 80vw;
    }
    .mb-2-r{
        margin-bottom: 5px !important;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .Box_Login {
        min-width: 70vw;
        max-width: 70vw;
    }
    .Box_custom {
        min-width: 80vw;
        max-width: 80vw;
    }
    .store-md{
        width: 50%;
    }
    .navBar_movil{
        flex-wrap: inherit;
    }
    .bar_logo{
        width: 50%;
    }
    .w-20{
        max-width: 40%;
        min-width: 40%;
    }
    .sepper_body{
        overflow-x: scroll;
    }
    .mr-2-r{
        margin-right: 0px !important;
    }
    .newOrder_alingInitial{
        justify-content: start !important;
    }
    .newOrder_tableConteiner{
        overflow-x: scroll;
        width: 80vw;
    }
    .mb-2-r{
        margin-bottom: 5px !important;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .Box_Login {
        min-width: 70vw;
        max-width: 70vw;
    }
    .Box_custom {
        min-width: 70vw;
        max-width: 70vw;
    }
    .store-md{
        width: 35%;
    }
    .newOrder_tableConteiner{
        overflow-x: scroll;
        width: 80vw;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .Box_Login {
        min-width: 60vw;
        max-width: 60vw;
    }
    .Box_custom {
        min-width: 60vw;
        max-width: 60vw;
    }
}

/* Large devices (desktops and up) */
@media (min-width: 1200px) and (max-width: 1365px) { 
    .Box_Login {
        max-height: 70vw;
        max-height: 70vw;
    }
    .Box_custom {
        max-height: 70vw;
        max-height: 70vw;
    }
}

