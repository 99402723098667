/* Global styles */
:root {
    --color_primary: #03BBDA;
    --color_secondary: #8FEBF5;
    --color_grey: #707070;
    --color_back: #F6F6F6;
}
#app_back{
    background-color: var(--color_back);
    /* width: 100% !important; */
    min-height: 100vh;
    margin: 0px !important;
}
#app_back-2{    
    padding: 0px !important;
    overflow-y: hidden;
}
.App_Content{
}
.navBar{
    background-color: white !important;
}
.navBar_logo{
    width: 80%;
}
.navBar_menu a{
    text-decoration: none;
    color: var(--color_grey) !important;
}
.navBar_menu-text{
    text-decoration: none;
    color: var(--color_grey) !important;
}
.navBar_menu button{
    text-decoration: none;
    color: var(--color_grey) !important;
}
.navBar_menu form{
    display: inline;
}
.navBar_menu a button span{ 
    color: var(--color_grey) !important;
}
.Box_Login{
    min-width: 20vw;
    max-width: 20vw;
    min-height: 70vh;
    max-height: 70vh;
    background-color: white !important;  
    margin-top: -10%; 
}
.Box_custom{
    min-width: 45vw;
    max-width: 45vw;
    min-height: 90vh;
    max-height: 90vh;
    background-color: white !important; 
    overflow: scroll;
}
.input_custom {
    padding: 13px 14px !important;
}
.Login_Logo{
    margin-top: 30px;
    margin-bottom: 30px;
}
.Login_Header{
    background-color: var(--color_back);
    padding-top: 1%;
    padding-bottom: 1%;
}
.Box_Login-Content{
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow-x: hidden;
}
/* Btn */
.Go_btn-primary{
    background-color: var(--color_secondary) !important;
    color: white !important;
}
.text-primary{
    color: var(--color_primary) !important;
}
.text-grey{
    color: var(--color_grey) !important;
}
.menu_settins{
    top: 7% !important;
    right: 4% !important;
}
.menu_notifications{
    top: 7% !important;
    right: 4% !important;
}
.img_layout{
    max-width: 35px;
    max-height: 35px;
    min-width: 35px;
    min-height: 35px;
}
.mr-3{
    margin-right: 15px;
}
.profile-md {
    width: 100px;
    height: 100px;
}
.store-md {
    width: 25%
}
.doc-sm{
    max-width: 50px;
    max-height: 50px;
}