.avatar_driver{
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}
.attach_btn{
    height: 40px;
}
.attach_loader{
    width: 30px!important;
    height: 30px!important;
}
