@import url(https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Text&display=swap);


/* Global styles */
:root {
    --color_primary: #03BBDA;
    --color_secondary: #8FEBF5;
    --color_grey: #707070;
    --color_back: #F6F6F6;
}
#app_back{
    background-color: #F6F6F6;
    background-color: var(--color_back);
    /* width: 100% !important; */
    min-height: 100vh;
    margin: 0px !important;
}
#app_back-2{    
    padding: 0px !important;
    overflow-y: hidden;
}
.App_Content{
}
.navBar{
    background-color: white !important;
}
.navBar_logo{
    width: 80%;
}
.navBar_menu a{
    text-decoration: none;
    color: #707070 !important;
    color: var(--color_grey) !important;
}
.navBar_menu-text{
    text-decoration: none;
    color: #707070 !important;
    color: var(--color_grey) !important;
}
.navBar_menu button{
    text-decoration: none;
    color: #707070 !important;
    color: var(--color_grey) !important;
}
.navBar_menu form{
    display: inline;
}
.navBar_menu a button span{ 
    color: #707070 !important; 
    color: var(--color_grey) !important;
}
.Box_Login{
    min-width: 20vw;
    max-width: 20vw;
    min-height: 70vh;
    max-height: 70vh;
    background-color: white !important;  
    margin-top: -10%; 
}
.Box_custom{
    min-width: 45vw;
    max-width: 45vw;
    min-height: 90vh;
    max-height: 90vh;
    background-color: white !important; 
    overflow: scroll;
}
.input_custom {
    padding: 13px 14px !important;
}
.Login_Logo{
    margin-top: 30px;
    margin-bottom: 30px;
}
.Login_Header{
    background-color: #F6F6F6;
    background-color: var(--color_back);
    padding-top: 1%;
    padding-bottom: 1%;
}
.Box_Login-Content{
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow-x: hidden;
}
/* Btn */
.Go_btn-primary{
    background-color: #8FEBF5 !important;
    background-color: var(--color_secondary) !important;
    color: white !important;
}
.text-primary{
    color: #03BBDA !important;
    color: var(--color_primary) !important;
}
.text-grey{
    color: #707070 !important;
    color: var(--color_grey) !important;
}
.menu_settins{
    top: 7% !important;
    right: 4% !important;
}
.menu_notifications{
    top: 7% !important;
    right: 4% !important;
}
.img_layout{
    max-width: 35px;
    max-height: 35px;
    min-width: 35px;
    min-height: 35px;
}
.mr-3{
    margin-right: 15px;
}
.profile-md {
    width: 100px;
    height: 100px;
}
.store-md {
    width: 25%
}
.doc-sm{
    max-width: 50px;
    max-height: 50px;
}
.chat_avatar_driver__1Estm{
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}
.chat_attach_btn__1GPrw{
    height: 40px;
}
.chat_attach_loader__EyQQj{
    width: 30px!important;
    height: 30px!important;
}

/* Global styles */
:root {
    --color_primary: #05396B;
    --color_secundary: #E67632;
    --color_grey: #707070;
    --color_back: #F6F6F6;
    --color_red: #fb2929;
    --color_error: #f44336;
}
div#root {
    overflow-x: hidden;
    overflow-y: clip;
}
.bg_history{
    background-color: #F6F6F6;
    background-color: var(--color_back);
    margin: 10px;
    padding: 10px;
}
.bg_grey{
    background-color: #F6F6F6 !important;
    background-color: var(--color_back) !important;
}
.bg_primary{
    background-color: #05396B !important;
    background-color: var(--color_primary) !important;
}
.bg_white{
    background-color: white !important;
}
.bg_secundary{
    background-color: #E67632 !important;
    background-color: var(--color_secundary) !important;
}
.text_secundary{
    color: #E67632 !important;
    color: var(--color_secundary) !important;
}
.text_white{
    color: white !important;
}
.text_primary{
    color: #05396B !important;
    color: var(--color_primary) !important;
}
.text_grey{
    color: #707070 !important;
    color: var(--color_grey) !important;
}
.text_error{
    color: #f44336 !important;
    color: var(--color_error) !important;
}
.text_lite-white{
    color: #E0E6ED !important;
}
.Page_title{
    color: #05396B !important;
    color: var(--color_primary) !important;
    font-weight: 600 !important;
}
.divider_grey{
    color: #707070 !important;
    color: var(--color_grey) !important;
    border: 1px solid #707070;
    border: 1px solid var(--color_grey);
}
.divider_blue{
    color: #05396B !important;
    color: var(--color_primary) !important;
    border: 1px solid #05396B;
    border: 1px solid var(--color_primary);
}
.box_content{
    background-color: white !important;   
    min-height: 40vh;
}
.Title_primary{
    color: #05396B;
    color: var(--color_primary);
    font-weight: bold;
    text-align: center;
}
/* Btn */
.Go_btn-primary{
    background-color: #E67632 !important;
    background-color: var(--color_secundary) !important;
    color: white !important;
}
.btn-grey{
    background-color: #777777 !important;
    color: white !important;
}
/* Component HomeSection */
.Home_Content{
    background-color: #05396B;
    background-color: var(--color_primary) ;
    color: white;
    font-weight: 600;
    text-decoration: none;
}
.Home_Img{
    width: 100%;
}
/* DashboardElement */
.dash_content{
    background-color:  #05396B;
    background-color:  var(--color_primary) ;
    color: white;
}
.dash_Img{
    margin-right: 25px;
    margin-left: 25px;
    width: 40px;
    height: 40px;
}
/* List item */
.ListItem_title{
    font-size: 1.3rem;
    font-weight: bolder;
    color: #707070;
    color: var(--color_grey) ;
}
.ListItem_info{
    font-size: .9rem;
    color: #707070;
    color: var(--color_grey) ;
}
/* Details */
.profileImg{
    width: 130px;
    height: 130px;
}
.fileUpload {
    display: none;
}
.labelFileImg{
    cursor: pointer;
}
.labelText{
    color: #707070;
    color: var(--color_grey) ;
    font-weight: bold;
}
.List_title{
    color: #707070;
    color: var(--color_grey);
    font-weight: bold;
    text-align: center;
}
.List_text{
    color: #707070;
    color: var(--color_grey);
    text-align: center;
}
/* history */
.historyImg{
    width: 65px;
    height: 65px;
    margin-right: 20px;
    border-radius: 50%;
}
.History_title{
    font-size: 1.2rem;
    color: #707070;
    color: var(--color_grey);
    text-align: center;
}
.Text_blod-grey{
    color: #707070;
    color: var(--color_grey);
    font-weight: bold;
    text-align: center;
}
.Text_grey{
    color: #707070;
    color: var(--color_grey);
    text-align: left;
}
.status_issues{
    background-color: #E67632;
    background-color: var(--color_secundary);
    color: white;
    padding:  3px 10px 3px 10px;
    border-radius: 5px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: small;
}
.status_ok{
    background-color: #05396B;
    background-color: var(--color_primary);
    color: white;
    padding:  3px 10px 3px 10px;
    border-radius: 5px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: small;
}
.status_error{
    background-color: #fb2929;
    background-color: var(--color_red);
    color: white;
    padding:  3px 10px 3px 10px;
    border-radius: 5px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: small;
}
.text_inline-down{
    display: flex;
    align-items: flex-end;
}
.No_Decoration{
    text-decoration: none;
}
.center_center{
    display: flex;
    align-items: center;
}
.reportBox_primary{
    background-color: #05396B;
    background-color: var(--color_primary);
    color: white;
    padding: 20px;
    text-align: center;
    font-weight: bold;
    font-size: larger;
}
.History_route{
    background-color: #05396B;
    background-color: var(--color_primary);
    color: white;
    padding:  2px 10px 2px 10px;
    border-radius: 5px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: small;
}
/* START CHAT STYLES*/
.chat_content{
    min-height: 60vh;
    max-height: 60vh;
    overflow: auto;
    padding: 20px;
}
.chat_contact{
    margin-bottom: 10px !important;
}

.chat_msg{
    margin-bottom: 10px;
    padding: 10px;
    max-width: 70%;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
.chat_msg-reciver{
    margin-bottom: 10px !important;
}
.previw_img{
    width: 100px !important;
    height: 100px !important;
    cursor: pointer;
}
.full_preview {
    width: auto!important;
    height: 90vh!important;
}
.previw_img-real{
    width: 100px !important;
    height: 100px !important;
}
.msg_sent{
    float: left!important;
    background: #FFF;
}
.msg_received{
    float: right!important;
    background: #05396B;
    background: var(--color_primary);
    color: #FFFF;
}
.file_chat_view{
    width: 100%;
    max-width: 300px;
    display: block;
}
.chat_msg a{
    color: #FFF;
}
.new_chat_container{
    padding: 10px;
    background: #F6F6F6;
    background: var(--color_back);
    margin-bottom: 10px;
}
.form_chat_user{
    width: 100%;
}
.no_msg{
    text-align: center;
}
.side_chats{
    max-height: 740px;
    height: 100%;
    overflow: auto;
}
.driver_item{
    width: 100%;
    cursor: pointer;
}
.driver_name{
    font-size: 1rem;
}
.driver_chat_item{
    margin: 5px 0px;
}
.driver_chat_item_avatar{
    width: 60px;
    height: 60px;
}
.avatar_x-large {
    width: 150px!important;
    height: 150px!important;
}
.avatar_large {
    width: 64px!important;
    height: 64px!important;
}
/* END CHAT STYLES*/
/* Modal */
.modal__Global{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.modal__Posotion{
    position: absolute;
    width: 400;
}
.modal__style{
    background-color: white;
    padding: 30px;
}
.modal__style-B{
    background-color: white;
    padding: 30px;
}
.rounded{
    border-radius: 50%;
}
.flex{
    display: flex;
}
/* File */
.pointer{
    cursor: pointer !important;
}
.fileUpload {
    display: none;
}
.upload_file{
    border: 2px dashed #05396B;
    border: 2px dashed var(--color_primary);
    padding: 3% 0px;
}
.upload_file-grey{
    border: 2px dashed #707070;
    border: 2px dashed var(--color_grey);
    padding: 5px 0;
}
.text_area-style{
    border: 1px solid #707070;
    border: 1px solid var(--color_grey);
    min-width: 100% !important;
    padding: 5px;
    margin-top: 5px;
}
.border_solid-grey{
    border: 1px solid #707070;
    border: 1px solid var(--color_grey);
}
.img_brand{
    max-width: 130px;
    min-width: 130px;
    max-height: 70px;
    min-height: 70px;
}
.img_user-list{
    max-width: 65px;
    max-height: 65px;
    min-width: 65px;
    min-height: 65px;
}
.img_vendor{
    max-width: 80px;
    max-height: 80px;
    min-width: 40px;
    min-height: 40px;
}
.d-none{
    display: none;
}
.modal__img{
    max-width: 300px;
    min-width: 300px;
    max-height: 250px;
    min-height: 250px;
}
.CC_notes-img{
    max-width: 250px;
    max-height: 250px;
}
.shadow{
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}
.try_again{
    text-decoration: underline;
    cursor: pointer;
}
.file-error{
    border: 1px solid #fb2929;
}
.file_inf{
    border: 1px solid #fb2929;
}
.approve{
    padding: 5px 10px 5px 10px;
}

.barcode{
    font-family: 'Libre Barcode 39 Text', cursive;
    font-size: 50px;
}

/* Customers */
.sepper_header{
    margin: 0 15%;
    padding: 25px 10%;
    background: #05396B;
    background: var(--color_primary);
}
.sepper_body{
    margin: 0 15%;
    padding: 5% 5%;
    background: white;
}
.sepper{
    width: 100%;
    background: #05396B;
    background: var(--color_primary);
}
.customer__list-label{
    font-weight: bold;
    color: #868686;
}
.customer__tile-home{
    font-weight: bold;
    font-style: italic;
    color: white;
}
.customer__list-text{
    font-weight: 500;
    color: #8E8E8E;
    
}
#go_sumary_address_movil{
    display: none;
}

.customer__hr{
    border-bottom: solid 1px #DBDBDB ;
    padding-bottom: 5px;
    padding-top: 5px;
}
.mr-2-r{
    margin-right: 10px !important;
}
.mr-2{
    margin-right: 10px !important;
}
.my-2 {
    margin: 10px 0 !important;
}
.mb-1 {
    margin-bottom: 5px !important;
}
.mb-3 {
    margin-bottom: 15px !important;
}
.mx-2 {
    margin: 0 10px !important;
}
.mr-3 {
    margin-right: 15px;
}
.m-2{
    margin: 10px;
}
.header__details{
    background-color: #05396B;
    background-color: var(--color_primary);
    padding: 20px 10%;
    color: white;
}
.py-3{
    padding: 15px 0;
}
.py-5{
    padding: 30px 0;
}
.pt-3{
    padding-top: 15px;
}
.p-3{
    padding: 15px;
}
.pt-1{
    padding-top: 5px;
}
.px-2{
    padding: 0 10px;
}
.table__header{
    background-color: #CECECE;
    color: #989898;
}
.w-20{
    max-width: 20%;
    min-width: 20%;
}
.w-100{
    width: 100%;
}
.w-80{
    width: 80%;
}
.w-60{
    max-width: 60%;
    min-width: 60%;
}
.w-250px {
    width: 250px !important;
}
.w-150px {
    width: 150px !important;
}
.fz-75{
    font-size: 75px !important;
}
.table_package table{
    border: 1px solid #CECECE;
    border-collapse: collapse;
}
.customers_comment{
    padding: 5px;
    background: #F8F8F8;
    color: #868686;
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.items-cen-cen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.customer_newPackage{
    text-decoration: underline;
    color: #E67632 !important;
    font-weight: 500;
    cursor: pointer;
}
.txt_blue-lite{
    color: #6494C2;
}    
.input_file {
    border: lightgray dashed 2px;
    padding: 10px 15px 10px 15px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
}
.input_file input {
    display: none;
}

.input_file label {
    cursor: pointer;
    font-size: small;
    font-family: inherit;
}
iframe {
    width: 100%;
    height: 100vh;
}

#go_gridContainer_step5{
    padding-right: 0%!important;
    padding-left: 0%!important;
}



#go_tableBulk{
    width: 100%;
    height: auto!important;
    margin-left: 0%;
   
}

#go_summaryBulkTitle{
    margin-top: -5%;
    margin-left: 0%;
}

#go_bulkTableRow{
    cursor: pointer;
    
}

#go_bulkTableRow:hover{
    background-color: rgb(206, 206, 206);
}

.go_bulkModal{
    
    margin: auto;
    margin-top: 5%;
    width: 70%;
    height: auto;
    background-color: white;
    padding:1%;
    padding-bottom: 5%;
}

.go_bulkModal_pickUpDeliveryData{
    display: flex;
}

.go_bulkModal_pickUpData{
    margin:1%;
    padding:2%;
    width: 50%;
    border: 1px solid #05396B;
    border-radius: 10px;
}

.go_bulkModal_pickUpData h1{
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    color:05396B;
    font-size: 20px;
    margin-top: -7%;
    background-color: white!important;
}

.go_bulkModal_deliveryData{
    margin:1%;
    padding:2%;
    width: 50%;
    border: 1px solid #05396B;
    border-radius: 10px;
}

.go_bulkModal_deliveryData h1{
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    color:05396B;
    font-size: 20px;
    margin-top: -7%;
    background-color: white!important;
    float: right;
}

.go_bulkModal_packageData{
    width: 100%;

}

.go_bulkModal_packageData{}


#headPrueba{
    background-color: #05396B!important;
}

.go_bulkModal_buttons{
    margin-top: 1%;
    width: 35%;
    float: right;


}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .Box_Login {
        min-width: 70vw;
        max-width: 70vw;
    }
    .Box_custom {
        min-width: 80vw;
        max-width: 80vw;
    }
    .store-md{
        width: 50%;
    }
    .navBar_movil{
        flex-wrap: inherit;
    }    
    .bar_logo{
        width: 50%;
    }
    .sepper_header{
        margin: 0 !important;
    }
    .sepper_body{
        margin: 0 !important;
        overflow-x: scroll;
    }
    .w-20{
        max-width: 40%;
        min-width: 40%;
    }
    .mr-2-r{
        margin-right: 0px !important;
    }
    .newOrder_alingInitial{
        justify-content: start !important;
    }
    .newOrder_tableConteiner{
        overflow-x: scroll;
        width: 80vw;
    }
    .mb-2-r{
        margin-bottom: 5px !important;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .Box_Login {
        min-width: 70vw;
        max-width: 70vw;
    }
    .Box_custom {
        min-width: 80vw;
        max-width: 80vw;
    }
    .store-md{
        width: 50%;
    }
    .navBar_movil{
        flex-wrap: inherit;
    }
    .bar_logo{
        width: 50%;
    }
    .w-20{
        max-width: 40%;
        min-width: 40%;
    }
    .sepper_body{
        overflow-x: scroll;
    }
    .mr-2-r{
        margin-right: 0px !important;
    }
    .newOrder_alingInitial{
        justify-content: start !important;
    }
    .newOrder_tableConteiner{
        overflow-x: scroll;
        width: 80vw;
    }
    .mb-2-r{
        margin-bottom: 5px !important;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .Box_Login {
        min-width: 70vw;
        max-width: 70vw;
    }
    .Box_custom {
        min-width: 70vw;
        max-width: 70vw;
    }
    .store-md{
        width: 35%;
    }
    .newOrder_tableConteiner{
        overflow-x: scroll;
        width: 80vw;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .Box_Login {
        min-width: 60vw;
        max-width: 60vw;
    }
    .Box_custom {
        min-width: 60vw;
        max-width: 60vw;
    }
}

/* Large devices (desktops and up) */
@media (min-width: 1200px) and (max-width: 1365px) { 
    .Box_Login {
        max-height: 70vw;
        max-height: 70vw;
    }
    .Box_custom {
        max-height: 70vw;
        max-height: 70vw;
    }
}


/*Cellphones from iphone 5*/

@media(min-width: 320px)and (max-width: 540px){
    /* .MuiContainer-root{
        padding-left: 0px!important;
        padding-right: 0px!important;
    } */

    .sepper_header{
        margin: 0 !important;
        padding-left:3%;
    }

    .sepper_body{
        padding: 5% 5% 5% 5%;
    }


    #go_gridContainer_dashboardCustomer{
         
        
    }

    #go_gridContainerXs_dashboardCustomer{
        max-width: 100%;
        flex-basis:94%;
        width: 100%!important;
    }

    

    #go_gridContainer_orderType{
        width:100%;
    }

    #go_selectorAdress{
        justify-content: start;
        padding-left: 25%;
    }

    #go_gridContainer_stepper_form{
        width:93%!important;
    }

    #go_gridContainer_stepper_buttons{
        justify-content: space-around!important;
    }

    #go_gridContainer_stepper_buttons button{
        margin:0.3rem!important;
    }

    #go_gridContainer_buttons{
        width: 100%!important;
        justify-content: space-around!important;
    }

    #go_gridContainer_buttons button{
        margin: 5%!important;
    }

    #go_gridContainer_step5{
        width: 93%!important;
        padding:1%!important;
    }

    .table__header{
        margin-top:0%; 
    }

    #go_tablePackages_th{
        font-size: 0.7rem;
    }

    #go_step4_form{
        width: 94%;
    }

    .text_area-style{
        min-width: 96%!important;
    }

    #go_sumary_address_movil{
        display: block;
        text-align: right;
        margin: 0%;
        line-height:1;
    }
    #go_sumary_address{
        display:none
    }

    #go_gridContainer_stepper_sumary_buttons{
        justify-content: space-around;
    }

    #go_gridContainer_stepper_sumary_buttons button{
        margin:1%;
    }

    #go_gridContainer_orderHostoryDetails{
        padding-right: 7%;
        padding-left: 1%;
    }



    #go_orderDetails_gridContainerButtons button{
        width: 10rem!important;
    }



    #go_boxContainer_customerDetails{
        padding:0%;
        padding-left: 1%;
        padding-right: 7%;
    }

    #go_history_buttonExport{
        width: 90px;
    }

    #go_historyTable_heders{
        display: none;
    }

    #go_historyTable_itemButtons{
        padding-top: 3%;
    }
}
/* stepper */
.MuiStepIcon-root.MuiStepIcon-active {
    color: white !important;
}
.MuiStepIcon-root.MuiStepIcon-active text.MuiStepIcon-text{
    fill: #05396B  !important;
}
.MuiStepLabel-label.MuiStepLabel-active{
    color: white !important;
}
.MuiStepLabel-label {
    color: #6494C2 !important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
    color: white !important;
}
.MuiStepLabel-label.MuiStepLabel-completed{
    color: white !important;
}
.MuiStepIcon-root{
    color: #6494C2 !important;
}
.MuiStepIcon-text{
    fill: #05396B  !important;
}

/* New Driver Stepper */
#newDriverStep div span span svg text.MuiStepIcon-text {
    fill: #fff !important;
}
#newDriverStep .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel span {
    color: rgba(0, 0, 0, 0.54) !important;
}
#newDriverStep .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel span span svg.MuiSvgIcon-root.MuiStepIcon-root {
    color: rgba(0, 0, 0, 0.38) !important;
}
#newDriverStep div span span svg.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active {
    color: #3f51b5 !important;
}
