@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Text&display=swap');

/* Global styles */
:root {
    --color_primary: #05396B;
    --color_secundary: #E67632;
    --color_grey: #707070;
    --color_back: #F6F6F6;
    --color_red: #fb2929;
    --color_error: #f44336;
}
div#root {
    overflow-x: hidden;
    overflow-y: clip;
}
.bg_history{
    background-color: var(--color_back);
    margin: 10px;
    padding: 10px;
}
.bg_grey{
    background-color: var(--color_back) !important;
}
.bg_primary{
    background-color: var(--color_primary) !important;
}
.bg_white{
    background-color: white !important;
}
.bg_secundary{
    background-color: var(--color_secundary) !important;
}
.text_secundary{
    color: var(--color_secundary) !important;
}
.text_white{
    color: white !important;
}
.text_primary{
    color: var(--color_primary) !important;
}
.text_grey{
    color: var(--color_grey) !important;
}
.text_error{
    color: var(--color_error) !important;
}
.text_lite-white{
    color: #E0E6ED !important;
}
.Page_title{
    color: var(--color_primary) !important;
    font-weight: 600 !important;
}
.divider_grey{
    color: var(--color_grey) !important;
    border: 1px solid var(--color_grey);
}
.divider_blue{
    color: var(--color_primary) !important;
    border: 1px solid var(--color_primary);
}
.box_content{
    background-color: white !important;   
    min-height: 40vh;
}
.Title_primary{
    color: var(--color_primary);
    font-weight: bold;
    text-align: center;
}
/* Btn */
.Go_btn-primary{
    background-color: var(--color_secundary) !important;
    color: white !important;
}
.btn-grey{
    background-color: #777777 !important;
    color: white !important;
}
/* Component HomeSection */
.Home_Content{
    background-color: var(--color_primary) ;
    color: white;
    font-weight: 600;
    text-decoration: none;
}
.Home_Img{
    width: 100%;
}
/* DashboardElement */
.dash_content{
    background-color:  var(--color_primary) ;
    color: white;
}
.dash_Img{
    margin-right: 25px;
    margin-left: 25px;
    width: 40px;
    height: 40px;
}
/* List item */
.ListItem_title{
    font-size: 1.3rem;
    font-weight: bolder;
    color: var(--color_grey) ;
}
.ListItem_info{
    font-size: .9rem;
    color: var(--color_grey) ;
}
/* Details */
.profileImg{
    width: 130px;
    height: 130px;
}
.fileUpload {
    display: none;
}
.labelFileImg{
    cursor: pointer;
}
.labelText{
    color: var(--color_grey) ;
    font-weight: bold;
}
.List_title{
    color: var(--color_grey);
    font-weight: bold;
    text-align: center;
}
.List_text{
    color: var(--color_grey);
    text-align: center;
}
/* history */
.historyImg{
    width: 65px;
    height: 65px;
    margin-right: 20px;
    border-radius: 50%;
}
.History_title{
    font-size: 1.2rem;
    color: var(--color_grey);
    text-align: center;
}
.Text_blod-grey{
    color: var(--color_grey);
    font-weight: bold;
    text-align: center;
}
.Text_grey{
    color: var(--color_grey);
    text-align: left;
}
.status_issues{
    background-color: var(--color_secundary);
    color: white;
    padding:  3px 10px 3px 10px;
    border-radius: 5px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: small;
}
.status_ok{
    background-color: var(--color_primary);
    color: white;
    padding:  3px 10px 3px 10px;
    border-radius: 5px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: small;
}
.status_error{
    background-color: var(--color_red);
    color: white;
    padding:  3px 10px 3px 10px;
    border-radius: 5px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: small;
}
.text_inline-down{
    display: flex;
    align-items: flex-end;
}
.No_Decoration{
    text-decoration: none;
}
.center_center{
    display: flex;
    align-items: center;
}
.reportBox_primary{
    background-color: var(--color_primary);
    color: white;
    padding: 20px;
    text-align: center;
    font-weight: bold;
    font-size: larger;
}
.History_route{
    background-color: var(--color_primary);
    color: white;
    padding:  2px 10px 2px 10px;
    border-radius: 5px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: small;
}
/* START CHAT STYLES*/
.chat_content{
    min-height: 60vh;
    max-height: 60vh;
    overflow: auto;
    padding: 20px;
}
.chat_contact{
    margin-bottom: 10px !important;
}

.chat_msg{
    margin-bottom: 10px;
    padding: 10px;
    max-width: 70%;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
.chat_msg-reciver{
    margin-bottom: 10px !important;
}
.previw_img{
    width: 100px !important;
    height: 100px !important;
    cursor: pointer;
}
.full_preview {
    width: auto!important;
    height: 90vh!important;
}
.previw_img-real{
    width: 100px !important;
    height: 100px !important;
}
.msg_sent{
    float: left!important;
    background: #FFF;
}
.msg_received{
    float: right!important;
    background: var(--color_primary);
    color: #FFFF;
}
.file_chat_view{
    width: 100%;
    max-width: 300px;
    display: block;
}
.chat_msg a{
    color: #FFF;
}
.new_chat_container{
    padding: 10px;
    background: var(--color_back);
    margin-bottom: 10px;
}
.form_chat_user{
    width: 100%;
}
.no_msg{
    text-align: center;
}
.side_chats{
    max-height: 740px;
    height: 100%;
    overflow: auto;
}
.driver_item{
    width: 100%;
    cursor: pointer;
}
.driver_name{
    font-size: 1rem;
}
.driver_chat_item{
    margin: 5px 0px;
}
.driver_chat_item_avatar{
    width: 60px;
    height: 60px;
}
.avatar_x-large {
    width: 150px!important;
    height: 150px!important;
}
.avatar_large {
    width: 64px!important;
    height: 64px!important;
}
/* END CHAT STYLES*/
/* Modal */
.modal__Global{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.modal__Posotion{
    position: absolute;
    width: 400;
}
.modal__style{
    background-color: white;
    padding: 30px;
}
.modal__style-B{
    background-color: white;
    padding: 30px;
}
.rounded{
    border-radius: 50%;
}
.flex{
    display: flex;
}
/* File */
.pointer{
    cursor: pointer !important;
}
.fileUpload {
    display: none;
}
.upload_file{
    border: 2px dashed var(--color_primary);
    padding: 3% 0px;
}
.upload_file-grey{
    border: 2px dashed var(--color_grey);
    padding: 5px 0;
}
.text_area-style{
    border: 1px solid var(--color_grey);
    min-width: 100% !important;
    padding: 5px;
    margin-top: 5px;
}
.border_solid-grey{
    border: 1px solid var(--color_grey);
}
.img_brand{
    max-width: 130px;
    min-width: 130px;
    max-height: 70px;
    min-height: 70px;
}
.img_user-list{
    max-width: 65px;
    max-height: 65px;
    min-width: 65px;
    min-height: 65px;
}
.img_vendor{
    max-width: 80px;
    max-height: 80px;
    min-width: 40px;
    min-height: 40px;
}
.d-none{
    display: none;
}
.modal__img{
    max-width: 300px;
    min-width: 300px;
    max-height: 250px;
    min-height: 250px;
}
.CC_notes-img{
    max-width: 250px;
    max-height: 250px;
}
.shadow{
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}
.try_again{
    text-decoration: underline;
    cursor: pointer;
}
.file-error{
    border: 1px solid #fb2929;
}
.file_inf{
    border: 1px solid #fb2929;
}
.approve{
    padding: 5px 10px 5px 10px;
}

.barcode{
    font-family: 'Libre Barcode 39 Text', cursive;
    font-size: 50px;
}

/* Customers */
.sepper_header{
    margin: 0 15%;
    padding: 25px 10%;
    background: var(--color_primary);
}
.sepper_body{
    margin: 0 15%;
    padding: 5% 5%;
    background: white;
}
.sepper{
    width: 100%;
    background: var(--color_primary);
}
.customer__list-label{
    font-weight: bold;
    color: #868686;
}
.customer__tile-home{
    font-weight: bold;
    font-style: italic;
    color: white;
}
.customer__list-text{
    font-weight: 500;
    color: #8E8E8E;
    
}
#go_sumary_address_movil{
    display: none;
}

.customer__hr{
    border-bottom: solid 1px #DBDBDB ;
    padding-bottom: 5px;
    padding-top: 5px;
}
.mr-2-r{
    margin-right: 10px !important;
}
.mr-2{
    margin-right: 10px !important;
}
.my-2 {
    margin: 10px 0 !important;
}
.mb-1 {
    margin-bottom: 5px !important;
}
.mb-3 {
    margin-bottom: 15px !important;
}
.mx-2 {
    margin: 0 10px !important;
}
.mr-3 {
    margin-right: 15px;
}
.m-2{
    margin: 10px;
}
.header__details{
    background-color: var(--color_primary);
    padding: 20px 10%;
    color: white;
}
.py-3{
    padding: 15px 0;
}
.py-5{
    padding: 30px 0;
}
.pt-3{
    padding-top: 15px;
}
.p-3{
    padding: 15px;
}
.pt-1{
    padding-top: 5px;
}
.px-2{
    padding: 0 10px;
}
.table__header{
    background-color: #CECECE;
    color: #989898;
}
.w-20{
    max-width: 20%;
    min-width: 20%;
}
.w-100{
    width: 100%;
}
.w-80{
    width: 80%;
}
.w-60{
    max-width: 60%;
    min-width: 60%;
}
.w-250px {
    width: 250px !important;
}
.w-150px {
    width: 150px !important;
}
.fz-75{
    font-size: 75px !important;
}
.table_package table{
    border: 1px solid #CECECE;
    border-collapse: collapse;
}
.customers_comment{
    padding: 5px;
    background: #F8F8F8;
    color: #868686;
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.items-cen-cen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.customer_newPackage{
    text-decoration: underline;
    color: #E67632 !important;
    font-weight: 500;
    cursor: pointer;
}
.txt_blue-lite{
    color: #6494C2;
}    
.input_file {
    border: lightgray dashed 2px;
    padding: 10px 15px 10px 15px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
}
.input_file input {
    display: none;
}

.input_file label {
    cursor: pointer;
    font-size: small;
    font-family: inherit;
}
iframe {
    width: 100%;
    height: 100vh;
}

#go_gridContainer_step5{
    padding-right: 0%!important;
    padding-left: 0%!important;
}



#go_tableBulk{
    width: 100%;
    height: auto!important;
    margin-left: 0%;
   
}

#go_summaryBulkTitle{
    margin-top: -5%;
    margin-left: 0%;
}

#go_bulkTableRow{
    cursor: pointer;
    
}

#go_bulkTableRow:hover{
    background-color: rgb(206, 206, 206);
}

.go_bulkModal{
    
    margin: auto;
    margin-top: 5%;
    width: 70%;
    height: auto;
    background-color: white;
    padding:1%;
    padding-bottom: 5%;
}

.go_bulkModal_pickUpDeliveryData{
    display: flex;
}

.go_bulkModal_pickUpData{
    margin:1%;
    padding:2%;
    width: 50%;
    border: 1px solid #05396B;
    border-radius: 10px;
}

.go_bulkModal_pickUpData h1{
    width:fit-content;
    color:05396B;
    font-size: 20px;
    margin-top: -7%;
    background-color: white!important;
}

.go_bulkModal_deliveryData{
    margin:1%;
    padding:2%;
    width: 50%;
    border: 1px solid #05396B;
    border-radius: 10px;
}

.go_bulkModal_deliveryData h1{
    width:fit-content;
    color:05396B;
    font-size: 20px;
    margin-top: -7%;
    background-color: white!important;
    float: right;
}

.go_bulkModal_packageData{
    width: 100%;

}

.go_bulkModal_packageData{}


#headPrueba{
    background-color: #05396B!important;
}

.go_bulkModal_buttons{
    margin-top: 1%;
    width: 35%;
    float: right;


}