/* stepper */
.MuiStepIcon-root.MuiStepIcon-active {
    color: white !important;
}
.MuiStepIcon-root.MuiStepIcon-active text.MuiStepIcon-text{
    fill: #05396B  !important;
}
.MuiStepLabel-label.MuiStepLabel-active{
    color: white !important;
}
.MuiStepLabel-label {
    color: #6494C2 !important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
    color: white !important;
}
.MuiStepLabel-label.MuiStepLabel-completed{
    color: white !important;
}
.MuiStepIcon-root{
    color: #6494C2 !important;
}
.MuiStepIcon-text{
    fill: #05396B  !important;
}

/* New Driver Stepper */
#newDriverStep div span span svg text.MuiStepIcon-text {
    fill: #fff !important;
}
#newDriverStep .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel span {
    color: rgba(0, 0, 0, 0.54) !important;
}
#newDriverStep .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel span span svg.MuiSvgIcon-root.MuiStepIcon-root {
    color: rgba(0, 0, 0, 0.38) !important;
}
#newDriverStep div span span svg.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active {
    color: #3f51b5 !important;
}